
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { UserInterface } from 'common-modules/src/store/interface/User';
import jwlWbt from '@/components/jwlWbt.vue';
import Wbt from '@/store/Wbt';

const CommonIcon = () => import('common-modules/src/components/CommonIcon.vue');
const CommonError = () => import('common-modules/src/components/CommonError.vue');

@Component({
  components: {
    CommonError,
    jwlWbt,
    CommonIcon,
  },
  computed: mapGetters([
    'currentUser',
  ]),
})
export default class WbtView extends Vue {
  currentUser!: UserInterface;

  loaded = false;
  wbt: Wbt | null = null;
  wbtId: string | null = null;
  error = null;

  @Watch('$route')
  fetchData (): void {
    this.loaded = false;
    this.wbtId = this.$route.params.wbtId;
    const { jwtToken } = this.$route.params;
    const getterType = jwtToken ? 'getData' : 'getPublicApiData';
    this.$store.dispatch(getterType, `wbt/public/${this.wbtId}`)
      .then((data) => {
        this.wbt = new Wbt(data);
        this.loaded = true;
      })
      .catch((e) => {
        this.error = e;
      });
  }

  mounted (): void {
    this.fetchData();
  }
}
