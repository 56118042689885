
import { mapGetters } from 'vuex';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import User from 'common-modules/src/store/User';
import Wbt from '@/store/Wbt';

@Component({
  computed: mapGetters([
    'currentUser',
  ]),
})
export default class JwlWbt extends Vue {
  currentUser!: User|undefined;

  @Prop(Object)
  jwlWbt!: Wbt;

  @Prop(Number)
  unitId!: number | null;

  @Prop([Number, String])
  wbtIndex!: number | string | null;

  @Prop([Number, String])
  wbtId!: number | string | null;

  @Prop(String)
  hasAdditional!: string;

  errors: any[] = [];
  wbtUrl: string | null = null;
  aiccData: Record<string, string> = {};

  initialize (): string {
    return 'true';
  }

  terminate (): void {
    this.commit();
  }

  getValue (cmiElement: string): string {
    return this.jwlWbt.aiccData[cmiElement];
  }

  setValue (cmiElement: string, value: string): void {
    this.aiccData[cmiElement] = value;
  }

  commit (): string {
    let returnValue = 'false';
    if (this.currentUser) {
      if (this.wbtId !== null) {
        // without unit
        this.$store.dispatch('commitWbtId', {
          aiccData: this.aiccData,
          additional: this.hasAdditional,
          wbtId: this.wbtId,
        }).then(() => {
          returnValue = 'true';
        }).catch((e) => {
          this.errors.push(e);
        });
      } else if (this.unitId !== null && this.wbtIndex !== null) {
        // with unit
        this.$store.dispatch('commitWbt', {
          unitCode: this.unitId,
          aiccData: this.aiccData,
          additional: this.hasAdditional,
          wbtIndex: this.wbtIndex,
        }).then(() => {
          returnValue = 'true';
        }).catch((e) => {
          this.errors.push(e);
        });
      }
    }

    return returnValue;
  }

  getLastError (): string {
    return '0';
  }

  getErrorString (errorCode: string): string {
    return '';
  }

  getDiagnostics (errorCode: string): string {
    return '';
  }

  created (): void {
    this.aiccData = this.jwlWbt.aiccData;
    if (this.jwlWbt.scormVersion === '2004') {
      window.API_1484_11 = {
        Initialize: () => this.initialize(),
        Terminate: () => this.terminate(),
        GetValue: (element: string) => this.getValue(element),
        SetValue: (element: string, value: string) => this.setValue(element, value),
        Commit: () => this.commit(),
        GetLastError: () => this.getLastError(),
        GetErrorString: (errorCode: string) => this.getErrorString(errorCode),
        GetDiagnostic: (errorCode: string) => this.getDiagnostics(errorCode),
      };
    } else {
      window.API = {
        LMSInitialize: () => this.initialize(),
        LMSFinish: () => this.terminate(),
        LMSGetValue: (element: string) => this.getValue(element),
        LMSSetValue: (element: string, value: string) => this.setValue(element, value),
        LMSCommit: () => this.commit(),
        LMSGetLastError: () => this.getLastError(),
        LMSGetErrorString: (errorCode: string) => this.getErrorString(errorCode),
        LMSGetDiagnostic: (errorCode: string) => this.getDiagnostics(errorCode),
      };
    }
    this.wbtUrl = this.jwlWbt.path;
  }
}
